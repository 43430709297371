<template>
  <v-form>
    <v-container fluid>
      <v-layout wrap>
        <v-flex xs12 sm6 md6>
          <v-dialog
            ref="dialog1"
            v-model="modal"
            :return-value.sync="hourbooking.date"
            persistent
            lazy
            full-width
            width="290px">
            <v-text-field
              slot="activator"
              v-model="getHourbookingDate"
              label="Datum"
              readonly
            ></v-text-field>
            <v-date-picker 
              locale="nl-nl"
              v-model="hourbooking.date" 
              @input="$refs.dialog1.save(hourbooking.date)" 
              scrollable>
            </v-date-picker>
          </v-dialog>
        </v-flex>
        <v-flex xs12 sm6 md6>
          <v-slider
            v-if="$vuetify.breakpoint.smAndDown"
            v-model="amount"
            :error-messages="amountErrors"
            required
            thumb-label="always"
            label="Uren"
            min="0"
            max="12"
            step="0.5"
          ></v-slider>
          <v-text-field
            v-if="$vuetify.breakpoint.mdAndUp"
            v-model="amount"
            :error-messages="amountErrors"
            required
            type="number"
            @input="$v.amount.$touch()"
            @blur="$v.amount.$touch()"
          >
          </v-text-field>
        </v-flex>
        <v-flex xs12 sm12 md12 lg12 xl12 d-flex>
          <span v-if="projectsLoading">loading projects..</span>
          <v-autocomplete
            v-if="!projectsLoading"
            v-model="hourbooking.projectId"
            :items="projects"
            :item-text="getProjectDescription"
            item-value="id"
            label="Project"
            required
            :error-messages="projectErrors"
            @input="$v.hourbooking.projectId.$touch();$v.hourbooking.description.$touch();"
            @blur="$v.hourbooking.projectId.$touch();$v.hourbooking.description.$touch();"
          >
            <template
              slot="item"
              slot-scope="data"
            >
              <template v-if="data.item.header != undefined">
                <v-list-tile-content v-text="data.item"></v-list-tile-content>
              </template>
              <template v-else>
                <v-list-tile-content>
                  <v-list-tile-title v-html="getProjectDescription(data.item)"></v-list-tile-title>
                </v-list-tile-content>
              </template>
            </template>
          </v-autocomplete>
        </v-flex>
        <v-flex 
            v-if="roles && roles.includes('Administrator')"
            xs12 sm12 md12 lg12 xl12 d-flex>
          <v-autocomplete
            v-model="hourbooking.employeeId"
            :items="employees"
            :item-text="getEmployeeName"
            item-value="id"
            label="Medewerker"
          >
            <template
              slot="item"
              slot-scope="data"
            >
              <template v-if="data.item.header != undefined">
                <v-list-tile-content v-text="data.item"></v-list-tile-content>
              </template>
              <template v-else>
                <v-list-tile-content>
                  <v-list-tile-title v-html="getEmployeeName(data.item)"></v-list-tile-title>
                </v-list-tile-content>
              </template>
            </template>
          </v-autocomplete>
        </v-flex>        
        <v-flex xs12 sm12 md12 lg12 xl12>
          <v-textarea
            v-model="hourbooking.description"
            :error-messages="descriptionErrors"
            :counter="200"
            label="Omschrijving"
            @input="$v.hourbooking.description.$touch()"
            @blur="$v.hourbooking.description.$touch()"
            auto-grow
            rows="1"
          ></v-textarea>
        </v-flex>
        <v-flex xs12 sm12 md12 lg12 xl12>
          <v-btn color="success" @click="submit">opslaan</v-btn>
          <v-btn color="info" @click="emitReset">annuleren</v-btn>
          <v-btn v-if="hourbooking.id" color="error" @click="emitDelete">verwijderen</v-btn>
          <span>{{submitStatus}}</span>
        </v-flex>
        <p v-if="hourbooking.modifiedBy" class="text-xs font-weight-thin">Urenboeking voor het laatst gewijzigd door {{ hourbooking.modifiedBy.firstname }} op {{ formatDate(hourbooking.modified) }}</p>
      </v-layout>
    </v-container>
  </v-form>
</template>

<script>
  import { validationMixin } from 'vuelidate'
  import { required, maxLength, between } from 'vuelidate/lib/validators'

  export default {
    props: ['hourbookingdata', 'roles'],
    mixins: [validationMixin],
    validations: {
      hourbooking: {
        description: { required: function(val) {
                var proj = this.projects.find(x => x.id === this.hourbooking.projectId);
                return (val || proj.type === 0);
            }, maxLength: maxLength(200) },
        projectId: { required }
      },
      amount: { required, between: between(0.5,12) }
    },
    data: () => ({
      submitStatus: null,
      modal: false,
      projectsLoading: false,
      projects: [],
      employees: [],
      hourbooking: [],
      amount: null
    }),
    watch: {
      amount(val) {
        if (val) {
          this.amount = Math.ceil(val*2)/2
        }
      }
    },
    computed: {
      descriptionErrors () {
        const errors = []
        if (!this.$v.hourbooking.$dirty) return errors
        !this.$v.hourbooking.description.maxLength && errors.push('Omschrijving mag maximaal 200 tekens bevatten')
        !this.$v.hourbooking.description.required && errors.push('Dit veld is verplicht')
        return errors
      },
      amountErrors () {
        const errors = []
        if (!this.$v.amount.$dirty) return errors
        !this.$v.amount.between && errors.push('Het aantal uur mag minimaal 0.5 en maximaal 12 zijn')
        !this.$v.amount.required && errors.push('Dit veld is verplicht')
        return errors
      },
      projectErrors () {
        const errors = []
        if (!this.$v.hourbooking.$dirty) return errors
        !this.$v.hourbooking.projectId.required && errors.push('Dit veld is verplicht')
        return errors
      },
      getHourbookingDate () {
        return this.$moment(this.hourbooking.date).format('dddd D MMMM')
      } 
    },
    methods: {
      submit() {
        this.hourbooking.amount = this.amount
        this.$v.amount.$touch()
        this.$v.hourbooking.$touch()
        if (this.$v.$invalid) {
          this.submitStatus = ''
        } else {
          if (this.hourbooking.id == undefined) {
          this.submitStatus = 'POSTING'
            this.$http.post('api/hourbooking/', this.hourbooking)
              .then(request => {
                if (request.status == 201) {
                  this.submitStatus = 'OK'
                  this.$emit('done')
                } else {
                  this.submitStatus = 'ERROR'
                }
              })
              .catch(() => { 
                console.error('post error!') /* eslint-disable-line no-console */
                this.submitStatus = 'ERROR'
              })
          } else {
          this.submitStatus = 'PUTTING'
            this.$http.put('api/hourbooking/' + this.hourbooking.id, this.hourbooking)
              .then(request => {
                if (request.status == 201) {
                  this.submitStatus = 'OK'
                  this.$emit('done')
                } else {
                  this.submitStatus = 'ERROR'
                }
              })
              .catch(() => { 
                console.error('put error!') /* eslint-disable-line no-console */
                this.submitStatus = 'ERROR'
              })
          }
        }
      },
      emitReset () {
        this.$v.hourbooking.$reset()
        this.$emit('reset')
      },
      emitDelete () {
        this.$v.hourbooking.$reset()
        this.$emit('deleteHourbooking')
      },
      getProjectDescription(project) {
        if (project != null && project != undefined && project.header == undefined) {
          return project.name + ' (' + project.organization + ')'
        }
      },
      getEmployeeName: function(employee) {
        return ((employee.firstname || '') + ' ' + (employee.surnamePrefix || '') + ' ' +  (employee.surname || '')).replace('  ', ' ')
      },
      formatDate(date) {
        return this.$moment(date).format('DD-MM-YYYY HH:mm')
      },
      getProjects () {
        this.projectsLoading = true
        this.projects = []
        var projectsApiEndpoint = 'api/project/my'
        if (this.roles && this.roles.includes('Administrator')) projectsApiEndpoint = 'api/project/'
        this.$http.get(projectsApiEndpoint)
          .then(request => {
            if (request.data && request.data.length > 0) {
              var lastType = -1
              var that = this
              request.data.forEach(function(project) {
                if (project.type != lastType) {
                  var header = ''
                  if (project.type == 0) header = 'Administratief'
                  if (project.type == 1) header = 'Productontwikkeling'
                  if (project.type == 2) header = 'Project'
                  if (project.type == 3) header = 'Service'
                  that.projects.push({
                    header
                  })
                  lastType = project.type
                }
                that.projects.push({
                  id: project.id,
                  name: project.name,
                  type: project.type,
                  organization: project.organization.name,
                })
              })
            }
            this.projectsLoading = false
          })
          .catch(() => { 
            console.error('api error!')  /* eslint-disable-line no-console */
            this.projectsLoading = false
          })
      },
      getEmployees () {
        this.employees = []
        this.$http.get('api/employees/')
          .then(request => {
            if (request.data) {
              this.employees = request.data
            }
          })
          .catch(() => { console.error('api error!') }) /* eslint-disable-line no-console */
      }
    },
    mounted: function() {
      if (this.hourbookingdata.date !== null 
        && this.hourbookingdata.date !== undefined) {
          this.hourbookingdata.date = this.hourbookingdata.date.substr(0, 10)
      }

      this.getProjects()
      if (this.roles && this.roles.includes('Administrator')) this.getEmployees()

      if (this.hourbookingdata != null) {
        this.hourbooking = this.hourbookingdata
      }
      this.amount = this.hourbooking.amount
    }
  }
</script>